@mixin new-gas-input-theme($theme) {
  $color: map-get($theme, color);
  $primary: map-get($color, primary);
  $accent: map-get($color, accent);
  $warn: map-get($color, warn);
  $background: map-get($color, background);
  $foreground: map-get($color, foreground);

  $nav-color: map-get($primary, A100);
  $nav-text: map-get(map-get($primary, contrast), A200);

  .new-gas-input {
    border-color: $nav-color;
    background-color: map-get($background, card);
  }

  .gas-description {
    color: map-get($foreground, secondary-text);
  }
}
