@use '@angular/material' as mat;
@include mat.core();

// Define a theme.
$primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$accent: mat.m2-define-palette(mat.$m2-purple-palette, A200, A100, A400);
$warn: mat.m2-define-palette(mat.$m2-red-palette);

$warn-icons: (error: map-get($warn, 500), warning: #FFC107);
$warn: map-merge($warn, $warn-icons);

$theme: mat.m2-define-light-theme((
  color: (
    primary: $primary,
    accent: $accent,
    warn: $warn
  ),
  typography: mat.m2-define-typography-config(),
  density: 0,
));

// Include all theme styles for the components.
@include mat.all-component-themes($theme);

@include mat.typography-hierarchy($theme);

@import './app/dive-plan/dive-plan.component.theme.scss';
@include dive-plan-theme($theme);

@import './app/dive-summary/dive-summary.component.theme.scss';
@include dive-summary-theme($theme);

@import './app/error-list/error-list.component.theme.scss';
@include error-list-theme($theme);

@import './app/depth-chart/depth-chart.component.theme.scss';
@include depth-chart-theme($theme);

@import './app/po2-chart/po2-chart.component.theme.scss';
@include po2-chart-theme($theme);

@import './app/end-chart/end-chart.component.theme.scss';
@include end-chart-theme($theme);

@import './app/tissues-ceiling-chart/tissues-ceiling-chart.component.theme.scss';
@include tissues-ceiling-chart-theme($theme);

@import './app/tissues-pn2-chart/tissues-pn2-chart.component.theme.scss';
@include tissues-pn2-chart-theme($theme);

@import './app/tissues-phe-chart/tissues-phe-chart.component.theme.scss';
@include tissues-phe-chart-theme($theme);

@import './app/current-stats/current-stats.component.theme.scss';
@include current-stats-theme($theme);

@import './app/new-depth-stats/new-depth-stats.component.theme.scss';
@include new-depth-stats-theme($theme);

@import './app/new-gas-input/new-gas-input.component.theme.scss';
@include new-gas-input-theme($theme);

@import './app/new-gas-stats/new-gas-stats.component.theme.scss';
@include new-gas-stats-theme($theme);

@import './app/new-time-stats/new-time-stats.component.theme.scss';
@include new-time-stats-theme($theme);

@import './app/ceiling-chart/ceiling-chart.component.theme.scss';
@include ceiling-chart-theme($theme);

@import './app/standard-gas-list/standard-gas-list.component.theme.scss';
@include standard-gas-list-theme($theme);

@import './app/dive-settings/dive-settings.component.theme.scss';
@include dive-settings-theme($theme);

@import './app/start-gas-stats/start-gas-stats.component.theme.scss';
@include start-gas-stats-theme($theme);

@import './app/change-depth/change-depth.component.theme.scss';
@include change-depth-theme($theme);

@import './app/maintain-depth/maintain-depth.component.theme.scss';
@include maintain-depth-theme($theme);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.tooltip-wide .mdc-tooltip__surface {
  max-width: unset;
}

sup, sub {
  vertical-align: baseline;
  position: relative;
  top: -0.4em;
}

sub {
  top: 0.4em;
}

.mat-icon {
  vertical-align: middle;
}

.hidden {
  display: none !important;
}
