@mixin maintain-depth-theme($theme) {
  $color: map-get($theme, color);
  $primary: map-get($color, primary);
  $background: map-get($color, background);

  $nav-color: map-get($primary, A100);

  .input-section {
    border-color: $nav-color;
    background-color: map-get($background, card);
  }
}
